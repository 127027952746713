<template>
  <div class="w-100 transition">
    <GenericReport
      title="Relatório de Materiais Médicos parados"
      :route="route"
      :fields="fields"
      :topFilters="topFilters"
      :sideFilters="sideFilters"
      tipoRelatorio="relatorioProcessos"
    />
  </div>
</template>

<script>
import moment from 'moment';

import GenericReport from '@/components/Table/GenericReport';

export default {
  name: 'relatorioProcessos',
  components: {
    GenericReport,
  },

  data() {
    return {
      route: 'report/medicoParados',
      fields: [
        {
          key: 'tipoDeMaterial',
          label: 'Tipo de Material',
        },
        {
          key: 'ID',
          label: 'ID',
        },
        {
          key: 'descricao',
          label: 'Descrição',
        },
        {
          key: 'medico',
          label: 'Médico',
        },
        {
          key: 'dataHora',
          label: 'Data / Hora',
        },
        {
          key: 'usuario',
          label: 'Usuário',
        },
        {
          key: 'ultimoProcesso',
          label: 'Última Leitura',
        },
        {
          key: 'tempoParado',
          label: 'Tempo Parado',
        },
      ],
      topFilters: {
        id_unidade: {
          type: 'genericSelect',
          route: 'unidade',
          defaultValue: 1,
          firstOption: {
            value: null,
            label: 'Unidade',
          },
        },
        date: {
          type: 'rangeDate',
          defaultInitDate: moment().subtract(7, 'days'),
          defaultEndDate: moment(),
        },
        id_sub_setor: {
          type: 'select',
          firstOption: {
            value: null,
            label: 'Última leitura',
          },
          options: [{
            value: 1,
            text: 'Expurgo',
          }, {
            value: 2,
            text: 'Preparo',
          }, {
            value: 3,
            text: 'Esterilização',
          }],
        },
      },
      sideFilters: {
        id: {
          type: 'input',
          label: 'ID',
        },
        descricao: {
          type: 'input',
          label: 'Descrição',
        },
        id_medico: {
          type: 'genericSelect',
          label: 'Médico',
          route: 'medico',
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
$primary-color-50: #209f85;

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transition {
  transition: 0.3s ease-in-out;
}

#report h2 {
  margin-bottom: 30px;
}

#report table {
  background-color: #FFF;
}

#report table thead th {
  color: #7E829F;
  padding-bottom: 50px;
  font-weight: 900;
}

</style>
